(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-authentication/assets/javascripts/use-kambi-token.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-authentication/assets/javascripts/use-kambi-token.js');
"use strict";


const {
  useState,
  useEffect
} = React;
const logger = new svs.core.log.Logger('components:oddset:kambi-authentication:use-kambi-token');
const {
  useKeepAlive,
  keepSessionAlive,
  fetchKambiToken
} = svs.components.oddset.kambiAuthentication;
const oneMinute = 60 * 1000;
const twentyFiveMinutes = oneMinute * 25;
const isSessionAlive = async token => {
  try {
    const sessionIsAlive = await keepSessionAlive(token);
    return sessionIsAlive;
  } catch (error) {
    return false;
  }
};
const getCachedToken = () => new Promise(resolve => {
  svs.core.userSession.get('oddsetAuthToken', async (error, data) => {
    if (error) {
      logger.warn('Unable to fetch data on user session.', error);
      resolve(undefined);
      return;
    }
    if (!(data !== null && data !== void 0 && data.token) || !(data !== null && data !== void 0 && data.timestamp) || !(data !== null && data !== void 0 && data.hashedPunterId)) {
      resolve(undefined);
      return;
    }
    const token = data.token;
    const hashedPunterId = data.hashedPunterId;
    const timePassed = Date.now() - data.timestamp;
    if (timePassed > twentyFiveMinutes) {
      resolve(undefined);
      return;
    }
    const sessionIsAlive = await isSessionAlive(token);
    if (sessionIsAlive) {
      logger.info('Token exists in userSession and is still valid, using cached token.');
      resolve({
        token,
        hashedPunterId
      });
      return;
    }
    resolve(undefined);
  });
});
const saveToCache = _ref => {
  let {
    token,
    hashedPunterId
  } = _ref;
  svs.core.userSession.set('oddsetAuthToken', {
    token,
    hashedPunterId,
    timestamp: Date.now()
  }, error => {
    if (error) {
      logger.warn('Unable to set token on users session.', error);
    } else {
      logger.info('Cached token on userSession.');
    }
  });
};
const useKambiToken = () => {
  const [token, setToken] = useState(undefined);
  const [hashedPunterId, setHashedPunterId] = useState(undefined);
  const [error, setError] = useState(undefined);

  if (error) {
    throw error;
  }
  useEffect(() => {
    const getToken = async () => {
      try {
        let tokenObject = await getCachedToken();
        if (!tokenObject) {
          logger.info('No valid token on userSession - fetching a fresh token.');
          tokenObject = await fetchKambiToken();
        }
        saveToCache({
          token: tokenObject.token,
          hashedPunterId: tokenObject.hashedPunterId
        });
        setToken(tokenObject.token);
        setHashedPunterId(tokenObject.hashedPunterId);
      } catch (error) {
        logger.error('Kambi login was unsuccessful.', error);
        setError(error);
      }
    };
    const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
    if (isLoggedIn) {
      getToken();
    }
  }, []);
  useKeepAlive(token, saveToCache);
  return {
    token,
    hashedPunterId
  };
};
setGlobal('svs.components.oddset.kambiAuthentication', {
  useKambiToken
});

 })(window);