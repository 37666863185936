(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/oddset/kambi-authentication/assets/javascripts/use-keep-alive.js') >= 0) return;  svs.modules.push('/components/oddset/kambi-authentication/assets/javascripts/use-keep-alive.js');
"use strict";


const {
  jupiterFetch
} = svs.core;
const {
  useEffect
} = React;
const logger = new svs.core.log.Logger('components:oddset:kambi-authentication:use-keep-alive');
const oneMinute = 60 * 1000;
const twentyFiveMinutes = oneMinute * 25;
const keepSessionAlive = async (token, isAliveCallback) => {
  const {
    kambiAuthApiUrl
  } = svs.oddset.kambiAuthentication.data;
  try {
    const kambiLoginRes = await jupiterFetch.fetch("".concat(kambiAuthApiUrl, "/punter/session.json"), {
      method: 'POST',
      svsOptions: {
        disableTelemetry: true
      },
      headers: {
        Authorization: "Bearer ".concat(token),
        'Content-Type': 'application/json',
        accept: 'application/json'
      }
    });
    if (kambiLoginRes.ok) {
      logger.info('Successful Kambi session keep alive.');
      if (isAliveCallback) {
        isAliveCallback(token);
      }
      return true;
    }
    logger.info('Kambi session is no longer alive.');
    return false;
  } catch (error) {
    throw new Error(error);
  }
};
const keepSessionAliveRecursiveLoop = (token, isAliveCallback) => {
  setTimeout(() => {
    const isLoggedIn = svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER);
    if (token && isLoggedIn) {
      try {
        keepSessionAlive(token, isAliveCallback);
      } catch (error) {
        logger.error('Unable to keep Kambi session alive', JSON.stringify(error));
      }
      keepSessionAliveRecursiveLoop(token, isAliveCallback);
    }
  }, twentyFiveMinutes); 
};
const useKeepAlive = (token, isAliveCallback) => {
  useEffect(() => {
    keepSessionAliveRecursiveLoop(token, isAliveCallback);
  }, [token, isAliveCallback]);
};
setGlobal('svs.components.oddset.kambiAuthentication', {
  useKeepAlive,
  keepSessionAlive
});

 })(window);